import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/BingoGamePage.css";
import global_config from "../config";
import logo from "../logo.svg";

const CreateGameURL = global_config.BackendUrl + global_config.CreateGameRoute;
const BingoGamePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [amount, setAmount] = useState(0);
  const [boards, setBoards] = useState([[]]); // Initialize with one empty board
  const [error, setError] = useState("");
  const [selectedBoards, setSelectedBoards] = useState(new Set());

  //   setSelectedBoards(new Set("asdf"));
  //   alert(selectedBoards);

  //   const clickStatus = new Array(80).fill(false);

  // Ensure at least one empty board is initialized when component mounts
  useEffect(() => {
    if (boards.length === 0) {
      setBoards([[]]);
    }
  }, []);

  const handleAddBoard = () => {
    setBoards([...boards, []]);
  };

  const handleRemoveBoard = (index) => {
    const updatedBoards = [...boards];
    updatedBoards.splice(index, 1);
    setBoards(updatedBoards);
  };

  const handleNumberClick = (number, boardIndex) => {
    // alert(number);

    // clickStatus[number - 1] = true;
    const updatedBoards = [...boards];

    // Ensure the board at the specified index is initialized
    if (!updatedBoards[boardIndex]) {
      updatedBoards[boardIndex] = [];
    }

    const board = updatedBoards[boardIndex];
    const numberIndex = board.indexOf(number);

    if (selectedBoards.has(number)) {
      selectedBoards.delete(number);
    } else {
      selectedBoards.add(number);
    }
    if (numberIndex === -1) {
      //   selectedBoards.add(number);
      // Number is not in the board, add it
      updatedBoards[boardIndex] = [...board, number];
    } else {
      //   selectedBoards.delete(number);

      // Number is already in the board, remove it
      updatedBoards[boardIndex] = board.filter((num) => num !== number);
    }
    console.log(selectedBoards);
    setSelectedBoards(selectedBoards);
    setBoards(updatedBoards);
  };

  const handleSubmit = async (e) => {
    try {
      // e.preventDefault();
      // alert("submitting");
      const addedBoards = [];
      for (let num of selectedBoards) {
        // alert(num);
        addedBoards.push(num);
      }
      console.log(addedBoards);
      //   alert(amount);
      const token = localStorage.getItem("token");
      const data = {
        amount,
        // boards: addedBoards,
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: CreateGameURL,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      let response = await axios.request(config);

      console.log(response);
      // alert("Bingo game created:", response.data);
      const gameId = response.data["_id"];
      console.log(gameId);
      localStorage.setItem("gameId", gameId);
      // data.add
      const newData = {
        boards: addedBoards || [],
      };

      let startGameConfig = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${global_config.BackendUrl}/game/${gameId}/start`,
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: newData,
      };

      response = await axios.request(startGameConfig);
      alert("Bingo game started:", response.data);

      await axios
        .get(`http://localhost:8087/refresh?gameId=${gameId}`)
        .then(() => {
          window.location.href = "/game";
        })
        .catch((error) => {
          console.log("err");
        });
    } catch (error) {
      console.log(error);
      alert("Failed to create bingo game");
    }
  };

  if (!isAuthenticated) {
    return (
      <p className="login-message">Please log in to create a bingo game.</p>
    );
  }

  return (
    <div className="bingo-container">
      <header>
        <div className="row">
          <div className="col c50">
            <div className="logo-block">
              <img className="logo" src={logo} alt="Let's Play Bingo Logo" />
              <img className="logo" src={logo} alt="Let's Play Bingo Logo" />
            </div>
          </div>
        </div>
      </header>
      {/* <h2 className="bingo-heading">Create Bingo Game</h2> */}
      <div className="grid-container">
        <div className="numbers-grid">
          {[...Array(80).keys()].map((number) => (
            <div
              key={number}
              className={`number-box ${
                selectedBoards.has(number + 1)
                  ? //   alert(clickStatus[number]);
                    "selected"
                  : "not"
              }`}
              onClick={() => {
                handleNumberClick(number + 1);
              }}
            >
              {number + 1}
            </div>
          ))}
        </div>
        <div className="input-section">
          <form className="bingo-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                id="amount"
                value={amount}
                defaultValue={0}
                min={0}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="input-group">
              <h4>Boards:</h4>
              {selectedBoards}
            </div>
            <button type="button" onClick={handleSubmit} className="submit-btn">
              Create Game
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default BingoGamePage;
