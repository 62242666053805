// LoginPage.js

import React, { useState } from "react";
import axios from "axios";
import config from "../config";

const loginAPIURL = config.BackendUrl + config.LoginRoute;

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    try {
      console.log("Backedn url");
      console.log(process.env);
      setLoading(true);
      const response = await axios.post(loginAPIURL, { username, password });

      // Assuming your backend returns a token upon successful login
      const { token, firstName } = response.data;
      console.log(response.data);
      // You can save the token to local storage or session storage for authentication
      localStorage.setItem("token", token);
      localStorage.setItem("firstName", firstName);

      // Redirect or perform any necessary action upon successful login
      // For example, redirecting to dashboard page
      window.location.href = "/create";
    } catch (error) {
      setError("Invalid username or password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Login</h2>
      <div style={styles.inputContainer}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
        />
      </div>
      <div style={styles.inputContainer}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
      </div>
      <button onClick={handleLogin} style={styles.button} disabled={loading}>
        {loading ? "Logging in..." : "Login"}
      </button>
      {error && <p style={styles.error}>{error}</p>}
    </div>
  );
};

const styles = {
  container: {
    width: "300px",
    margin: "0 auto",
    marginTop: "100px",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  heading: {
    marginBottom: "20px",
  },
  inputContainer: {
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
  },
  error: {
    color: "red",
    marginTop: "10px",
  },
};

export default LoginPage;

function MyComponent() {
  return (
    <>
      <div className="box">2</div>
      <style jsx>
        {`
          .box {
            font-family: Arial, sans-serif;
            border-color: rgba(31, 172, 8, 1);
            border-style: solid;
            border-width: 6px;
            background-color: #f3f3f3;
            align-items: center;
            width: 50px;
            justify-content: center;
            height: 50px;
            padding: 0 25px 1px;
          }
          @media (max-width: 991px) {
            .box {
              white-space: initial;
              padding: 0 20px;
            }
          }
        `}
      </style>
    </>
  );
}

function MyComponent2() {
  return (
    <>
      <div className="div">2</div>
      <style jsx>{`
        .div {
          font-family: Arial, sans-serif;
          gap: 0px;
          padding-bottom: 1px;
        }
      `}</style>
    </>
  );
}
