// App.js

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import LoginPage from "./pages/login";
import LetsPlayBingo from "./pages/LetsPlayBingo";
// import CreateCreateBingoGamePage from "./pages/createGame";
import CreateCreateBingoGamePage from "./pages/createGame";

// import DashboardPage from "./DashboardPage";
// import ProtectedRoute from "./ProtectedRoute";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <LetsPlayBingo path="/game" />
        <CreateCreateBingoGamePage path="/create" />

        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  );
};

export default App;
